import React from "react"

import Layout from "../components/layout"
import Seo from "../components/Seo"
import { ImageBed, Container, Row, Col } from "../components/styled"
import Picture from "../components/Picture"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container>
      <Row>
        <Col p5="true">
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          <h4>
            Sorry, the page you are looking for has been re/moved.
            <br />
            Or perhaps you typed the wrong address ....
          </h4>
          <ImageBed nohover>
            <Picture
              name={"swan"}
              modelName={"Andrey Bond"}
              place="Moscow"
              filename="swan.jpg"
            />
          </ImageBed>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
